import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Tooltip } from "antd";
import { Button } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faMicrophone,
  faImage,
  faSpinner,
  faSquare,
  faStop,
  faHeadphones,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { imageValidation } from "../../../../../utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import clsx from "clsx";
import { SPEECH_ACCESS_PROJECTS } from "../../../../../constants";
import "./styles.scss";
import { AUDIO_ACCESS_PROJECTS } from "../../../../../constants";

const InputBox = React.forwardRef(
  (
    {
      projectId,
      sendMessage,
      isLoading,
      imageLoading,
      isProjectApp,
      projectData,
      appConfig,
      stopResponseGenerating,
      selectedImages = [],
      removeSelectedImage,
      selectImage,
      selectedModel,
      audioBoxPreviewHandler,
    },
    ref
  ) => {
    // STATES
    const [message, setMessage] = useState("");

    // CONST VALS
    const [form] = Form.useForm();
    const uploadImageInput = useRef(null);
    const audioBoxPreview =
      isProjectApp && AUDIO_ACCESS_PROJECTS.includes(projectId);
    const speechPreview =
      SPEECH_ACCESS_PROJECTS.includes(projectId) || !isProjectApp;
    const inputPlaceHolder = appConfig?.inputBoxPlaceholder;
    const discalimerMessage = appConfig?.desclaimerMessage;
    const imageSupportWarning =
      selectedImages.length > 0 && !selectedModel.imageSupport;
    const {
      transcript,
      listening,
      resetTranscript,
      browserSupportsSpeechRecognition,
      browserSupportsContinuousListening,
    } = useSpeechRecognition();

    // HANDLERS
    const recordingHandler = () => {
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
        return;
      }
      setMessage("");
      SpeechRecognition.startListening({
        continuous: browserSupportsContinuousListening,
      });
    };

    const uploadImageHandler = (e) => {
      const files = e.target.files;
      let uploadedfiles = [];
      if (!files) return;
      Array.from(files).forEach((item) => {
        if (imageValidation(item)) uploadedfiles.push(item);
      });
      selectImage(uploadedfiles);
      if (uploadImageInput.current) uploadImageInput.current.value = "";
    };

    const setMessageHandler = (e) => {
      setMessage(e.target.value);
    };

    const generateMessageHandler = () => {
      if (isLoading) return stopResponseGenerating();
      if (message.trim() === "") return;
      setMessage("");
      sendMessage(message);
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        generateMessageHandler();
      }
    };

    // HOOKS
    const inputRef = useCallback(
      (node) => {
        if (!node) return;
        // set textarea height
        node.style.height = "31px";
        const nodeHeight = node.scrollHeight;
        node.style.height = nodeHeight + "px";

        // set chat wrapper height
        if (!ref || !ref.current) return;
        const contentSpacing = window.innerWidth > 768 ? 95 : 65;
        const screenHeight = window.innerWidth > 768 ? 130 : 115;
        const defaultHeight =
          selectedImages.length > 0
            ? screenHeight + contentSpacing
            : screenHeight;

        const textareaHeight =
          nodeHeight > 20 ? nodeHeight - 40 : nodeHeight - 20;
        const calcheight = textareaHeight + defaultHeight;
        ref.current.style.height = `calc(100% - ${calcheight}px)`;
      },
      [message, selectedImages]
    );

    useEffect(() => {
      if (transcript !== "") {
        setMessage(transcript);
      }
    }, [transcript]);

    return (
      <div className="input-wrapper">
        <div className="inputbox-wrapper">
          <div className={clsx("input-box", audioBoxPreview && "reduced")}>
            <Form className="input-form" form={form}>
              {!isProjectApp && (
                <label htmlFor="image-uploader" className="upload-button">
                  image uplaod
                  <input
                    type="file"
                    multiple
                    ref={uploadImageInput}
                    disabled={imageLoading}
                    id="image-uploader"
                    onChange={uploadImageHandler}
                  />
                  <FontAwesomeIcon
                    className={clsx("upload-icon", imageLoading && "loading")}
                    icon={imageLoading ? faSpinner : faImage}
                  />
                </label>
              )}
              {/* PROMPT GENERATOR */}
              <textarea
                className="ant-input"
                ref={inputRef}
                placeholder={
                  isProjectApp
                    ? projectData?.inputBoxPlaceholder ?? inputPlaceHolder
                    : inputPlaceHolder
                }
                value={message}
                onChange={setMessageHandler}
                onKeyDown={handleKeyPress}
              />
              {speechPreview && (
                <Tooltip
                  title={
                    !browserSupportsSpeechRecognition
                      ? "Browser doesn't support speech recognition"
                      : listening
                      ? "Stop microphone"
                      : "Use microphone"
                  }
                >
                  <button
                    className="upload-button"
                    onClick={recordingHandler}
                    disabled={!browserSupportsSpeechRecognition}
                  >
                    <FontAwesomeIcon
                      className="upload-icon"
                      icon={listening ? faStop : faMicrophone}
                    />
                  </button>
                </Tooltip>
              )}
              {/* GENERATE BUTTON */}
              <Button
                type="submit"
                className="send-btn"
                invertedTheme
                boldText
                onClick={generateMessageHandler}
                disabled={message.trim() === "" && !isLoading}
              >
                <FontAwesomeIcon icon={isLoading ? faSquare : faArrowUp} />
              </Button>
            </Form>
            <div className="images-box">
              <div className="selected-images">
                {selectedImages.map((item, index) => (
                  <div className="image-box" key={index}>
                    <div className="image-wrapper">
                      <img src={item} alt="" />
                    </div>
                    <span
                      role="button"
                      tabIndex={index}
                      className="delete-btn"
                      onKeyDown={() => removeSelectedImage(index)}
                      onClick={() => removeSelectedImage(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {imageSupportWarning && (
              <p className="desclaimer-message">
                The current model chosen does not support images so this request
                will be processed using another model.
              </p>
            )}
          </div>
          {audioBoxPreview && (
            <button className="headset-button" onClick={audioBoxPreviewHandler}>
              <FontAwesomeIcon icon={faHeadphones} />
            </button>
          )}
        </div>
        {/* PROMPT TAGLINE */}
        <div className={clsx("tagline", imageSupportWarning && "warning")}>
          {isProjectApp
            ? projectData?.desclaimerMessage ?? discalimerMessage
            : discalimerMessage}
        </div>
      </div>
    );
  }
);

export default InputBox;
