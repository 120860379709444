import { call, fork, put, take, takeEvery } from "redux-saga/effects";
import {
  callRequest,
  GET_MEDIA_LISTING,
  GENERATE_UPLOAD_URL,
  UPLOAD_MEDIA,
  GENERATE_DOWNLOAD_URL,
  DELETE_MEDIA,
  UPLOAD_IMAGE,
  UPLOAD_AUDIO,
} from "../../config/web-service";
import { ALERT_TYPES, TOAST_MESSAGES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  uploadImageRequest,
  getMediaListingRequest,
  getMediaListingSuccess,
  generateUploadURLRequest,
  uploadMediaRequest,
  generateDownloadURLRequest,
  deleteMediaRequest,
  uploadAudioRequest,
} from "../slicers/general";
import {
  manipulateGenerateUrlData,
  manipulateMediaListing,
} from "../../data-manipulator/general";

// UPLOAD IMAGE
function* uploadImage() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(uploadImageRequest.type);
    try {
      const response = yield call(callRequest, {
        url: UPLOAD_IMAGE,
        payload,
      });
      responseCallback?.(response.status, response);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// UPLOAD AUDIO
function* uploadAudio() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(uploadAudioRequest.type);
    try {
      const response = yield call(callRequest, {
        url: UPLOAD_AUDIO,
        payload,
      });
      responseCallback?.(response.status, response);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET MEDIA LISTING
function* getMediaListing() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getMediaListingRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_MEDIA_LISTING,
        payload,
      });
      if (response.status) {
        yield put(
          getMediaListingSuccess(manipulateMediaListing(response?.data?.files))
        );
      }
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GENERATE UPLOAD URL
function* generateUploadUrl() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(generateUploadURLRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GENERATE_UPLOAD_URL,
        payload,
      });
      if (response.status) {
        responseCallback?.(
          true,
          manipulateGenerateUrlData(response.data, payload)
        );
      } else {
        responseCallback?.(false, response);
        toastAlert(response.message, ALERT_TYPES.ERROR);
      }
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// UPLOAD MEDIA
function* uploadMedia(action) {
  const {
    payload: { url, payload, responseCallback },
  } = action;

  try {
    const urlobj = { ...UPLOAD_MEDIA, baseurl: url };
    const response = yield call(callRequest, {
      url: urlobj,
      payload,
    });
    responseCallback?.(true, response);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// GENERATE DOWNLOAD URL
function* generateDownloadUrl() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(generateDownloadURLRequest.type);

    try {
      const response = yield call(callRequest, {
        url: GENERATE_DOWNLOAD_URL,
        payload,
      });
      responseCallback?.(response?.status, response);

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// DELETE MEDIA
function* deleteMedia() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(deleteMediaRequest.type);

    try {
      const response = yield call(callRequest, {
        url: DELETE_MEDIA,
        payload,
      });
      responseCallback?.(response?.status, response);
      toastAlert(
        response.message || TOAST_MESSAGES.FILE_DELETE_SUCCESS,
        response.status ? ALERT_TYPES.SUCCESS : ALERT_TYPES.ERROR
      );
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

export default function* root() {
  yield fork(uploadImage);
  yield fork(uploadAudio);
  yield fork(getMediaListing);
  yield fork(generateUploadUrl);
  yield takeEvery(uploadMediaRequest.type, uploadMedia);
  yield fork(generateDownloadUrl);
  yield fork(deleteMedia);
}
